.u-text {


	&-light {
		font-weight: $_font-weight-light !important;
	}

	&-normal {
		font-weight: $_font-weight-normal !important;
	}

	&-bold {
		font-weight: $_font-weight-bold !important;
	}

	&-indent {
		text-indent: 1em !important;
	}

	&-note {
		margin-left: 1em !important;
		text-indent: -1em !important;
	}

	&-nowrap {
		white-space: nowrap !important;
	}

	&-breakword {
		word-wrap: break-word !important;
		overflow-wrap: break-word !important;
	}
	
	&-none {
		text-transform: none !important;
	}

	&-lowercase {
		text-transform: lowercase !important;
	}

	&-uppercase {
		text-transform: uppercase !important;
	}

	&-capitalize {
		text-transform: capitalize !important;
	}

	&-smallcaps {
		font-variant: small-caps !important;
	}

	&-vertical {
		writing-mode: vertical-rl !important;
		text-orientation: mixed !important;
	}

	&-vertical--upright {
		text-orientation: upright !important;
	}
	&-top {
		vertical-align: top !important;
	}

	&-middle {
		vertical-align: middle !important;
	}

	&-bottom {
		vertical-align: bottom !important;
	}
	
	&-left {
		text-align: left !important;
	}
	
	&-justify {
		text-align: justify !important;
		text-justify: inter-ideograph !important;
	}
	
	&-center {
		text-align: center !important;
		text-indent: $_font-spacing !important;
	}

	&-right {
		text-align: right !important;
	}
	
	&_nbr{
		br{
			display: none;
		}
	}

	@each $display in map-keys($breakpoints-min) {
		@include mq-min($display) {
			
			&_nbr-#{$display}{
				br{
					display: none;
				}
			}
			&_br-#{$display}{
				br{
					display: inline;
				}
			}
			&_left-#{$display} {
				text-align: left !important;
				text-indent: 0 !important;
			}
			&_justify-#{$display} {
				text-align: justify !important;
				text-justify: inter-ideograph !important;
				text-indent: 0 !important;
			}
			&_center-#{$display} {
				text-align: center !important;
				text-indent: $_font-spacing !important;
			}
			&_right-#{$display} {
				text-align: right !important;
				text-indent: 0 !important;
			}
		}
	}
}