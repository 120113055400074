/*	=============================================================================
	#caption
	
	注釈・凡例のレイアウトのスタイルです。
	フォントサイズ・レイアウトの調整のみになります。
	フォントファミリーやカラーの設定は適宜追加をお願い致します。
	
	凡例アイコンをbefore要素で追加しています。
	床暖房範囲・収納はcssでのスタイリングで対応する形にしています。
	WIC・SICなどは文字表示のみです。
	通風・採光・アウトフレームは画像での書き出しになるので、画像データ差し替えで対応してください。
	
	<div class="c-caption">
		<span class="floor">：床暖房範囲</span>
		<span class="storage">：収納</span>
		<span class="wind">通風</span>
		<span class="light">採光</span>
		<span class="wic">：ウォークインクロゼット</span>
		<span class="sic">：シューズインクロゼット</span>
		<span class="outframe">：アウトフレーム設計</span>
		<p>※掲載の間取図は、設計段階の図面を基に作成したもので、実際とは異なる場合がございます。予めご了承ください。</p>
	</div>
	
	========================================================================== */
.c-caption{
	@include font-size(1.1rem);
	text-align: justify;
	&.-white{
		color: $_color-white;
	}
	span{
		display: block;
		@include mq-min(md){
			display: inline;
			padding-right:15px;
		}
	}
	@include mq-print(){
		display: none;
	}
    
    &-right{
        text-align: right;
    }
	
	span::before{
		content:"";
		display:inline-block;
		width:3em;
		margin:0;
		padding:0;
		margin-right:0.1em;
	}
	//床暖房
	.floor::before{
		height:1em;
        background:#fbe6ef;
		border:1px solid #999;
	}
	//収納
	.storage::before{
		height:1em;
        background:#eee6f2;
		border:1px solid #999;
	}
	//ウォークインクローゼット
	.wic::before{
		content:"WIC：";
		width:auto;
	}
	//シューズインクローゼット
	.sic::before{
		content:"SIC：";
		width:auto;
	}
	//納戸
	.nando::before{
		content:"N：";
		width:auto;
	}
	//サービススペース
	.service::before{
		content:"S：";
		width:auto;
	}
	//通風
	.wind::before{
		height: 1em;
		background: url(../img/shared/wind.svg) left center no-repeat;
		background-size: contain;
	}
	//採光
	.light::before{
		height: 1em;
		background: url(../img/shared/light.svg) left center no-repeat;
		background-size: contain;
	}
	//アウトフレーム
    .outframe::before{
		width: 1.4em;
		height: 1.4em;
		background: url(../img/shared/outframe.svg) left center no-repeat;
		background-size: contain;
	}
}