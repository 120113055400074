.c-slide{
	overflow: inherit;
	.swiper-pagination{
		bottom: -3rem;
		&-bullet{
			background: $_color-white;
			border-radius: 0;
			opacity: 1;
			width: 10px;
			height: 10px;
			&-active{
				background: $_color-black;
				border: 1px solid $_color-white;
				box-sizing: border-box;
			}
		}
	}
	
	&__control{
		position: absolute;
		z-index: 9999;
		bottom: 0;
		left: 0;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: stretch;
		@include mq-max(md){
			transform-origin: bottom left;
			transform:scale(.5);
		}
	}
	
	&__prev {
		display: block;
		width: 50px;
		height: 50px;
		position: relative;
		z-index: 9999;
		font-size: 3rem;
		color: $_color-white;
		outline: none;
		background: rgba($_color-black,.8);
		margin: 0;
		cursor: pointer;
		&::before {
			content: "\f053";
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			display: block;
			position: absolute;
			@include centering(y);
			left: 1.2rem;
		}
	}
	
	&__next {
		display: block;
		width: 50px;
		height: 50px;
		position: relative;
		z-index: 9999;
		font-size: 3rem;
		color: $_color-white;
		outline: none;
		background: rgba($_color-black,.8);
		margin-left: 1px;
		cursor: pointer;
		&::before {
			content: "\f054";
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			display: block;
			position: absolute;
			@include centering(y);
			left: 1.5rem;
		}
	}
}