/*	=============================================================================
	top
	========================================================================== */

.p-top {
	$cd: '../img/top/';
	$self: &;
	
	&-hero{
		width: calc(100% - 4rem);
		height: 60vh;
		margin: 6rem auto 0;
		overflow: hidden;
		border-radius: 2rem;
		@include mq-min(lg){
			height: 80vh;
		}
		
		&__img{
			width: 100%;
			height: 100%;
			display: block;
			object-fit: cover;
			object-position: 10% 50%;
		}
	}
	
	&-company{
		width: 100%;
		height: auto;
		display: block;
		position: relative;
		&::after{
			content: "";
			width: 60%;
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background: url(#{$cd}bg.png) bottom left no-repeat;
			background-size: contain;
			opacity: .2;
			pointer-events: none;
			z-index: 1;
			@include mq-min(lg){
				width: 40%;
				opacity: .3;
			}
		}
	}
	
	
	&-title{
		@include font-size(4rem);
		line-height: 2;
		letter-spacing: .1em;
		font-weight: normal;
		font-style: normal;
		font-family: $_font-en_serif;
		color: #2d333c;
	}
	&-holder{
		@include font-size(2rem);
		line-height: 2;
		letter-spacing: .1em;
		font-weight: normal;
		font-style: normal;
		font-family: $_font-ja_serif;
		color: #2d333c;
	}
	
	&-list{
		width: 100%;
		height: auto;
		display: block;
		margin-top: 4rem;
		
		&__item{
			padding: .5rem 1rem;
			border-bottom: 1px solid #999;
			@include mq-min(lg){
				display: flex;
				flex-flow: row nowrap;
				justify-content: flex-start;
				align-items: center;
				padding: 2rem 4rem;
			}
		}
		
		&__title{
			@include font-size(1.8rem);
			line-height: 2;
			font-weight: normal;
			font-style: normal;
			font-family: $_font-ja_serif;
			@include mq-min(lg){
				width: 140px;
				@include font-size(1.4rem);
			}
		}
		
		&__detail{
			@include font-size(1.4rem);
			line-height: 2;
			font-weight: normal;
			font-style: normal;
			font-family: $_font-ja_serif;
			margin-top: .5rem;
			@include mq-min(lg){
				margin-top: 0;
			}
		}
	}
}