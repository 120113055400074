/*	=============================================================================
	link	
	========================================================================== */

.c-link {
	font-size: 1em;
	color: $_color-link;
	position: relative;

	&::before {
		@include fontawesome(solid);
		margin-right: .5em;
	}

	&::after {
		@include fontawesome(solid);
		margin-left: .5em;
	}

	&_maps {
		&::after {
			content: "\f3c5";
		}
	}

	&_external {
		&::after {
			content: '\f360';
		}
	}

	&_arrow {
		&::after {
			content: '\f054';
		}
	}

	&_back {
		&::before {
			content: '\f053';
		}
	}

	&_bottom {
		&::after {
			content: '\f078';
		}
	}

	&_wait {
		color: lighten($_color-link, 30%);
		pointer-events: none;
	}

	@include mq-hover {
		@include transition(.5s, all);

		&:hover {
			color: $_color-link_hover;
		}
	}
}

.c-hover{
	@include mq-hover{
		@include transition(all , .5s);
		&:hover{
			opacity: .5;
		}
	}
}