/*	=============================================================================
	grid
	
	========================================================================== */

.c-grid{
	width:100%;
	margin: 0 auto;
	height: auto;
	display: block;
	
	@include mq-print(){
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-start;
	}
	
	&_xs{
		width: calc( 100% + 10px);
		margin: 0 -5px;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-start;
		@include mq-min(sm){
			width: $_row;
			margin: $_row-gutter;
		}
	}
	
	@include mq-min(sm){
		width: $_row;
		margin: $_row-gutter;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-start;
	}
	
	&_half{
		width: $_row;
		margin: $_row-gutter;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-start;
	}
	
	&_nogutter{
		width: 100%;
		margin: 0 auto;
		@include mq-min(sm){
			width: 100%;
			margin: 0 auto;
		}
		
		.c-col{
			margin: 0 !important;
		}
	}
}
.c-col {
	$parent : ".c-grid" ;
	width: 100%;
	height: auto;
	display: block;
	margin: #{$_gutter-basic} auto 0;
	&:first-child{
		margin-top: 0;
	}
	

	&_half {
		flex-basis: map-get($_grids, 6);
		max-width: map-get($_grids, 6);
		margin: $_gutter;
		
		#{$parent}_nogutter &{
			flex-basis: 50%;
			max-width: 50%;
		}
	}
	
	@for $i from 1 through 12 {
		@if $i < 12{
			&_xs#{$i} {
				flex-basis: calc( ( ( 100% / 12 ) * #{$i} ) - 10.1px ) ;
				max-width: calc( ( ( 100% / 12 ) * #{$i} ) - 10.1px );
				margin: 10px 5px 0;
				&:first-child{
					margin-top: 10px;
				}
				.c-grid_nogutter &{
					flex-basis: calc((( 100% / 12 )  * #{$i} ) - .1px ) ;
					max-width: calc((( 100% / 12 )  * #{$i} ) - .1px ) ;
				}
			}
		}
	}
	
	@include mq-min(sm) {
		flex-basis: map-get($_grids, 12);
		max-width: map-get($_grids, 12);
		margin: $_gutter;
		
		@for $i from 1 through 12 {
			@if $i < 12{
				&_xs#{$i} {
					flex-basis: calc( ( ( 100% / 12 ) * #{$i} ) - 10.1px );
					max-width: calc( ( ( 100% / 12 ) * #{$i} ) - 10.1px );
					margin: 10px 5px 0;
					&:first-child{
						margin-top: 10px;
					}
				}
			}
		}
	
		&_half {
			flex-basis: map-get($_grids, 6);
			max-width: map-get($_grids, 6);
			margin: $_gutter;
		}

		@each $number,
		$size in $_grids {
			&_sm#{$number} {
				flex-basis: $size;
				max-width: $size;
				margin: $_gutter;
				&:first-child{
					margin: $_gutter;
				}
			}
		}

		@each $number,
		$size in $_no-grids {
			#{$parent}_nogutter &_sm#{$number} {
				flex-basis: $size;
				max-width: $size;
				margin: 0;
			}
		}
	}

	@include mq-min(md) {

		@each $number,
		$size in $_grids {
			&_md#{$number} {
				flex-basis: $size;
				max-width: $size;
				margin: $_gutter;
				&:first-child{
					margin: $_gutter;
				}
			}
		}

		@each $number,
		$size in $_no-grids {
			#{$parent}_nogutter &_md#{$number} {
				flex-basis: $size;
				max-width: $size;
				margin: 0;
			}
		}
	}

	@include mq-min(lg) {

		@each $number,
		$size in $_grids {
			&_lg#{$number} {
				flex-basis: $size;
				max-width: $size;
				margin: $_gutter;
				&:first-child{
					margin: $_gutter;
				}
			}
		}

		@each $number,
		$size in $_no-grids {
			#{$parent}_nogutter &_lg#{$number} {
				flex-basis: $size;
				max-width: $size;
				margin: 0;
			}
		}
	}
	@include mq-print(){
		@each $number,
		$size in $_grids {
			&_lg#{$number} {
				flex-basis: $size;
				max-width: $size;
				margin: $_gutter;
			}
		}

		@each $number,
		$size in $_no-grids {
			#{$parent}_nogutter &_lg#{$number} {
				flex-basis: $size;
				max-width: $size;
				margin: 0;
			}
		}
	}

	@include mq-min(xl) {

		@each $number,
		$size in $_grids {
			&_xl#{$number} {
				flex-basis: $size;
				max-width: $size;
				margin: $_gutter;
				&:first-child{
					margin: $_gutter;
				}
			}
		}
		
		@each $number,
		$size in $_no-grids {
			#{$parent}_nogutter &_xl#{$number} {
				flex-basis: $size;
				max-width: $size;
				margin: 0;
			}
		}
	}
}