@font-face {
    font-family: "YakuHanJP";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/YakuHanJP/YakuHanJP-Medium.woff2") format("woff2"),
    url("../fonts/YakuHanJP/YakuHanJP-Medium.woff") format("woff");
}


@font-face {
    font-family: "YakuHanMP";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/YakuHanMP/YakuHanMP-Medium.woff2") format("woff2"),
    url("../fonts/YakuHanMP/YakuHanMP-Medium.woff") format("woff");
}