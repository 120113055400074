/*	=============================================================================
	main
	========================================================================== */

.l-main{
	$cd: '../img/shared/';
	$self: &;
	width: 100%;
	height: auto;
	display: block;
	overflow: hidden;
	background: #dbdeed;
}
.l-section{
	width: 100%;
	height: auto;
	display: block;
	margin: 0;
	padding: 4rem 0;
	@include mq-min(lg){
		padding: 8rem 0;
	}
	
	.l-main > &{
		padding: 8rem 0;
		@include mq-min(lg){
			padding: 12rem 0;
		}
	}
}