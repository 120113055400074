/*	=============================================================================
	メディアクエリ関連
	========================================================================== */


// -----------------------------------------------------------------
// media query
// 引数$breakpointに渡された値をブレイクポイントにしたmedia query設定を出力する（モバイルファースト）
// -----------------------------------------------------------------
@mixin mq-min($breakpoint: md) {
	@media #{map-get($breakpoints-min, $breakpoint)} {
		@content;
	}
}

// -----------------------------------------------------------------
// media query（pc first）
// 引数$breakpointに渡された値をブレイクポイントにしたmedia query設定を出力する（PCファースト）
// -----------------------------------------------------------------
@mixin mq-max($breakpoint: md) {
	@media #{map-get($breakpoints-max, $breakpoint)} {
		@content;
	}
}

// -----------------------------------------------------------------
// media query（hover）
// hover 判別するメディアクエリ。
// -----------------------------------------------------------------

@mixin mq-hover() {
	@media (hover: hover) {
		@content;
	}

	@media screen and (-ms-high-contrast: none) {
		@content;
	}
}

//多分使わないけど念の為。PCファーストで書いた場合はこちらを利用。
@mixin mq-touch() {
	@media (hover: none) {
		@content;
	}
}

// -----------------------------------------------------------------
// media query（dark mode）
// ダークモード対応
// -----------------------------------------------------------------

@mixin mq-dark() {
	@media (prefers-color-scheme: dark) {
		@content;
	}
}

@mixin js-dark(){
	body[theme="dark"] &{
		@content;
	}
}


// -----------------------------------------------------------------
// media query（for print）
// 印刷用
// -----------------------------------------------------------------

@mixin mq-print() {
	@media print {
		@content;
	}
}

// -----------------------------------------------------------------
// media query（for IE11）
// IE用
// -----------------------------------------------------------------
@mixin mq-ie() {
	@media screen and (-ms-high-contrast: none) {
		@content;
	}
}


/*	=============================================================================
	アニメーション関連
	========================================================================== */

// -----------------------------------------------------------------
// transition設定
// アニメーションのブレ対策でbackface-visibilityを追加
// -----------------------------------------------------------------
@mixin transition($speed: .5s, $property: all) {
	transition: $property $speed;
	backface-visibility: hidden;
}


// -----------------------------------------------------------------
// keyframes生成
// 引数$animation-nameにアニメーション名を指定。
// -----------------------------------------------------------------
@mixin keyframes($animation-name: animaton) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}

	@-moz-keyframes #{$animation-name} {
		@content;
	}

	@keyframes #{$animation-name} {
		@content;
	}
}

// -----------------------------------------------------------------
// animation生成
// 引数$animation-nameに生成したkeyframesのアニメーション名を指定。
// -----------------------------------------------------------------
@mixin animation($animation-name) {
	-webkit-animation: $animation-name;
	-moz-animation: $animation-name;
	animation: $animation-name;
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	animation-fill-mode: both;
}



/*	=============================================================================
	レイアウト調整
	========================================================================== */

// -----------------------------------------------------------------
// clearfixのスタイル挿入
// 引数$importantに"important"を渡すと'!important'を指定。
// -----------------------------------------------------------------
@mixin clearfix($important: false) {
	@if ($important=="important") {
		$important: unquote("!important");
	}

	@else {
		$important: null;
	}

	&:after {
		content: ""$important;
		display: block $important;
		clear: both $important;
	}
}

// -----------------------------------------------------------------
// サイズ不確定要素の上下左右中央配置
// 親要素がposition:relativeになっていることが前提
// 引数$axis: xy（上下左右方向）、x（横方向のみ）、y（縦方向のみ）
// 省略時は上下左右中央配置
// -----------------------------------------------------------------
@mixin centering($axis: xy) {
	position: absolute;

	@if ($axis==x) {
		left: 50%;
		transform: translateX(-50%);
	}

	@elseif ($axis==y) {
		top: 50%;
		transform: translateY(-50%);
	}

	@else {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

// -----------------------------------------------------------------
//　ボタンスタイルのリセット
// -----------------------------------------------------------------

@mixin buttonReset {
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none !important;
	padding: 0;
	appearance: none;
	text-decoration: none;
}

/*	=============================================================================
	テキスト関連
	========================================================================== */

// -----------------------------------------------------------------
// テキストの両端揃え
// -----------------------------------------------------------------
@mixin justify() {
	text-align: justify;
	text-justify: inter-ideograph;
}

// -----------------------------------------------------------------
// line-heightを追加した時の行頭揃え
// -----------------------------------------------------------------
@mixin lhCrop($line-height) {
	&::before {
		content: '';
		display: block;
		height: 0;
		width: 0;
		margin-top: calc((1 - #{$line-height}) * 0.5em);
	}
}

// -----------------------------------------------------------------
// 文字が溢れた際の表示を「…」 にする（1行）
// -----------------------------------------------------------------
@mixin line-truncate() {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

// -----------------------------------------------------------------
// 文字が溢れた際の表示を … にする（複数行対応, Chrome/Safariのみ）
// 引数$linesに設定された行まで表示（デフォルト2行）
// -----------------------------------------------------------------
@mixin lines-truncate($lines: 2) {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $lines;
}

// -----------------------------------------------------------------
// Fontawsome設定 フォントウェイト変更
// -----------------------------------------------------------------
@mixin fontawesome($weight:solid) {
	font-family: "font awesome 5 free";

	@if ($weight==regular) {
		font-weight: 400;
	}

	@else {
		font-weight: 900;
	}
}