/*	=============================================================================
	space / margin padding
	========================================================================== */

.u-mt{
	&-0{
		margin-top: 0 !important;
	}
	
	&-minimum{
		margin-top: 1rem !important;
	}
	
	&-small{
		margin-top: 3rem !important;
	}
	
	&-medium{
		margin-top: $_space-basic * 1.3  !important;
		@include mq-min(lg){
			margin-top: $_space-basic * 2  !important;
		}
	}
	
	&-large{
		margin-top: $_space-basic * 2  !important;
		@include mq-min(lg){
			margin-top: $_space-basic * 3.5  !important;
		}
	}
	&-media--sm{
		@include mq-max(sm){
			margin-top: $_space-basic !important;
		}
	}

}

.u-mb{
	&-0{
		margin-bottom: 0 !important;
	}
	
	&-small{
		margin-bottom: $_space-basic !important;
	}
	
	&-medium{
		margin-bottom: $_space-basic * 1.3  !important;
		@include mq-min(lg){
			margin-bottom: $_space-basic * 2  !important;
		}
	}
	
	&-large{
		margin-bottom: $_space-basic * 2  !important;
		@include mq-min(lg){
			margin-bottom: $_space-basic * 3.5  !important;
		}
	}
}

// centering
.u-mr-center {
  margin-left: auto !important;
  margin-right: auto !important;
}


.u-pt{
	&-0{
		padding-top: 0 !important;
	}
	
	&-small{
		padding-top: $_space-basic !important;
	}
	
	&-medium{
		padding-top: $_space-basic * 1.3  !important;
		@include mq-min(lg){
			padding-top: $_space-basic * 2  !important;
		}
	}
	
	&-large{
		padding-top: $_space-basic * 2  !important;
		@include mq-min(lg){
			padding-top: $_space-basic * 3.5  !important;
		}
	}
}

.u-pb{
	&-0{
		padding-bottom: 0 !important;
	}
	
	&-small{
		padding-bottom: $_space-basic !important;
	}
	
	&-medium{
		padding-bottom: $_space-basic * 1.3  !important;
		@include mq-min(lg){
			padding-bottom: $_space-basic * 2  !important;
		}
	}
	
	&-large{
		padding-bottom: $_space-basic * 2  !important;
		@include mq-min(lg){
			padding-bottom: $_space-basic * 3.5  !important;
		}
	}
}