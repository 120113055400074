/*	=============================================================================
	form
	========================================================================== */

.c-submit {
	@include buttonReset;
	width: 100%;
	max-width: 230px;
	height: auto;
	display: block;
	margin: 0 auto;
	padding: 1.5rem 0;
	position: relative;
	background: $_color-black;
	border:1px solid $_color-black;
	
	
	@include font-size(1.4rem);
	line-height: 1;
	letter-spacing: .15em;
	text-align: center;
	text-indent: .15em;
	font-family: $_font-en_sans-serif;
	color: $_color-white;
	
	&_login{
		background: $_color-main;
		border-color: $_color-main;
		&::after{
			content: "";
			display: block;
			position: absolute;
			@include centering(y);
			right: 0;
			width: 3rem;
			height: 1px;
			background: $_color-white;
		}
		@include mq-hover{
			@include transition(all , .5s);
			&::after{
				@include transition(all , .5s);
			}
			&:hover{
				background: $_color-black;
				color: $_color-white;
				&::after{
					width: 1rem;
				}
			}
		}
	}

	&_icon{
		&::after{
			position: absolute;
			@include centering(y);
			right: 1.6rem;
			content: "\f002";
			@include fontawesome;
		}
		@include mq-hover{
			@include transition(all , .5s);
			&::after{
				@include transition(right , .5s);
			}
			&:hover{
				background: $_color-white;
				color: $_color-black;
				&::after{
					right: 1rem;
				}
			}
		}
	}

}

.c-form{
	
	&__input{
		border: none;
		background: lighten($_color-main,65%);
		font-size: 1.6rem;
		font-weight: normal;
		font-style: normal;
		font-family: $_font-ja_serif !important;
		padding: 1rem;
		width: 100%;
		height: 100%;
	}
}
