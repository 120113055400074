/*	=============================================================================
	wrapper
	========================================================================== */

.c-wrapper{
	width: $_width;
	max-width: $_max-width;
	margin: 0 auto;
	
	&-caption{
		background: rgba($_color-white,.85);
		padding: 2%;
	}
	
	&-popup{
		width: $_width;
		max-width: 54rem;
		margin: 0 auto;
	}
	
	&-small{
		width: $_width;
		max-width: $_small-width;
		margin: 0 auto;
	}
		
	&-medium{
		width: $_width;
		max-width: $_medium-width;
		margin: 0 auto;
	}
	
	&-fluid{
		width: 100%;
		max-width: 100%;
	}
	&_white{
		background: $_color-white;
		padding: 1.5rem;
		@include mq-min(lg){
			padding: 4.5rem;
		}
	}
}

