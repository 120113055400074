/*	=============================================================================
	text	
	========================================================================== */
// 13px 15px 17px mv:80px


.c-text {
	@include font-size(1.6rem);
	@include justify();
	letter-spacing: $_font-spacing;
	line-height: 2.4;
	font-weight: normal;
	font-style: normal;
	color: $_color-text;
	@include mq-min(lg){
		@include font-size(1.7rem);
	}
	
	&.-caption{
		@include font-size(1.2rem);
		@include mq-min(lg){
			@include font-size(1.2rem);
		}
	}

	&.-small {
		@include font-size(1.3rem);
		@include mq-min(lg){
			@include font-size(1.4rem);
		}
	}

	&.-medium {
		@include font-size(2rem);
		@include mq-min(lg){
			@include font-size(2.2rem);
		}
	}

	&.-large {
		@include font-size(2.6rem);
		@include mq-min(lg){
			@include font-size(3rem);
		}
	}

	&.-white{
		color: $_color-white;
	}
	
	sup{
		font-size: .6em;
	}
	small{
		font-size: .6em;
	}
	
}