/*	=============================================================================
	heading	
	========================================================================== */

.c-head{
	@include font-size(2.6rem);
	@include justify();
	letter-spacing: $_font-spacing;
	line-height: 2;
	font-weight: normal;
	font-style: normal;
	position: relative;
	@include mq-min(lg){
		@include font-size(2.8rem);
	}
	&.-medium{
		@include font-size(3.4rem);
	}
	&.-small{
		@include font-size(2.4rem);
	}
	&.-large{
		@include font-size(4.8rem);
	}
	
	&.-white{
		color: $_color-white;
	}
	
	&.-en{
		font-family: $_font-en_serif;
	}
	
	sup , small{
		font-size: .45em;
	}
	span{
		position: relative;
	}
	sup{
		line-height: 0;
		vertical-align:text-top;
		position: absolute;
		top: 2px;
		margin-left: -8px;
	}
}