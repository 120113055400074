/*	=============================================================================
	grid utility
	========================================================================== */

.u-grid{
	&-nowrap{
		flex-wrap: nowrap;
	}
	&-reverse{
		flex-direction: row-reverse;
	}
	&-justify-center{
		justify-content: center;
	}
	&-justify-end{
		justify-content:flex-end;
	}
	&-justify-between{
		justify-content: space-between;
	}
	&-align-stretch{
		align-items: stretch;
	}
	
	&-align-center{
		align-items: center;
	}
	
	&-align-baseline{
		align-items:baseline;
	}

	&-align-end{
		align-items:flex-end;
	}
}

.u-col {
	&-align-baseline {
		align-self: baseline;
	}

	&-align-center {
		align-self: center;
	}

	&-align-start {
		align-self: flex-start;
	}

	&-align-end {
		align-self: flex-end;
	}

	&-align-stretch {
		align-self: stretch;
	}
}