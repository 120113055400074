// ==========================================================================
// base
// ==========================================================================

html {
	box-sizing: border-box;
	font-family: $_font-serif;
}

*,
*:before,
*:after {
	box-sizing: border-box;
	// position: relative;
	z-index: 1;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: "palt";
	margin: 0;
	letter-spacing: $_font-spacing;
	position: relative;
}


/* Safari用のハックは、Chromeに適用されないようにする */
@supports (-webkit-touch-callout: none) {
  body {
    /* Safari用のハック */
    height: -webkit-fill-available;
  }
}

:root {
	font-size: $_root-font-size-percent;
}

img {
	height: auto;
	vertical-align: middle;
}

[src$=".svg"],
[data$=".svg"] {
	width: 100%;
}

a {
	text-decoration: none;

	@include mq-hover {
		&:hover {
			text-decoration: none;
		}
	}
}

strong,i,cite,em,address,dfn {
	font-style: normal;
	font-weight: normal;
}


h1,h2,h3,h4,h5,h6,p,dd,dl,ol,ul,figure,img {
	padding: 0;
	margin: 0;
	font-weight: normal;
	font-style: normal;
}

li {
	list-style: none;
}

//計測タグの余計な高さが出る場合の非表示設定
#tag {
	display: none !important;
}

button{
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none;
}