// float left
.u-fl-left {
	float: left;
}

// float right
.u-fl-right {
	float: right;
}

// clearfix
.u-clarfix {
	@include clearfix('important');
}