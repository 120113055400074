.c-burger {
	width: 3.5rem;
	height: 1.8rem;
	position : absolute;
	@include centering(xy);

	&.isOpen {
		span {
			top: 50%;
			transform: translate(-50%, -50%);

			&:first-child {
				top: 50%;
				transform: translate(-50%, -100%) rotate(45deg);
			}

			&:nth-of-type(2) {
				opacity: 0;
			}

			&:last-child {
				top: 50%;
				transform: translate(-50%, -100%) rotate(-45deg);

			}
		}
	}

	&__icon {
		height: 14px;
		position: relative;

	}

	&__text {
		color: $_color-white;
		text-align: center;
		margin-top: 7px;
		line-height: 7px;
	}

	span {
		width: 100%;
		height: 1px;
		background: $_color-white;
		display: block;
		position: absolute;
		left: 50%;
		transform-origin: center center;
		transition: all .3s ease-in-out;

		&:first-child {
			top: 0;
			transform: translate(-50%, 0);
		}

		&:nth-of-type(2) {
			top: 50%;
			transform: translate(-50%, 0);
		}

		&:last-child {
			top: 100%;
			transform: translate(-50%, 0);
		}
	}
}