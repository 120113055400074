/*	=============================================================================
	button	
	========================================================================== */

.c-button {
	$self: &;
	width: 100%;
	display: block;
	background: $_color-black;
	border: 1px solid $_color-white;
	@include font-size(1.6rem);
	text-align: center;
	padding: 1em 0;
	position: relative;
	margin: 0 auto;
	@include mq-print(){
		display: none;
	}
	
	&.current{
		pointer-events: none;
		opacity: .45;
	}
	

	span {
		display: block;
		width: 100%;
		line-height: 1em;
		letter-spacing: .15em;
		text-align: center;
		text-indent: .15em;
		font-family: $_font-ja_serif;
		color: $_color-white;
		position: relative;
		z-index: 2;
		&::after{
			position: absolute;
			@include centering(y);
			right: 1.6rem;
		}
		
	}

	&_external {
		span {
			&::after {
				content: '\f360';
				@include fontawesome;
			}
		}
	}

	&_arrow {
		&::before{
			display: none;
		}
		span {
			&::before{
				display: none;
			}
			&::after {
				content: '\f061';
				@include fontawesome;
			}
		}
	}

	&_pdf {
		span {
			&::after {
				content: "\f1c1";
				@include fontawesome;
			}
		}
	}
	
	&_print , &_close , &_maps ,&_small{
		@include font-size(1.4rem);
		padding: 1.25em 0;
	}
	
	&_print {
		span {
			&::after {
				content: "\f02f";
				@include fontawesome;
			}
		}
	}
	
	&_close {
		background: lighten($_color-black,40%);
		border-color: lighten($_color-black,40%);
		span {
			&::after {
				content: "\f00d";
				@include fontawesome;
			}
		}
	}
	&_maps {
		span {
			&::after {
				content: "\f3c5";
				@include fontawesome;
			}
		}
	}
	
	&_disable{
		position: relative;
		pointer-events: none;
		&::after{
			content: "";
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			background: rgba($_color-black,.6);
			top: 0;
			left: 0;
				z-index: 2;
		}
		
		&::before{
			content: "Coming Soon";
			width: 100%;
			height: auto;
			position: absolute;
			display: block;
			@include centering(xy);
			@include font-size(2rem);
			line-height: 1;
			text-align:center;
			font-weight: normal;
			font-style: normal;
			font-family: $_font-ja_serif;
			color: $_color-white;
			z-index: 3;
		}
	}

	@include mq-hover {
		@include transition(.5s, all);
		&::after {
			content: "";
			width: 0%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			@include transition(all ,.5s);
			display: block;
			background: $_color-white;
			z-index: 1;
		}
		
		&::before{
			@include transition(.5s , all);
		}
		
		
		
		&_disable{
			&::after{
				content: "";
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				background: rgba($_color-black,.6);
				top: 0;
				left: 0;
				z-index: 2;
			}
		}
		span {
			@include transition(color , .5s);

			&::before,
			&::after {
				@include transition(all, .5s);
			}
		}

		&:hover {
			
			&::before{
				background: $_color-main;
			}
			&::after {
				width: 100%;
			}

			span {
				color: $_color-main;
				
				&::before{
					border-color: $_color-main;
				}

				&::after {
					right: 1rem;
				}
			}
		}
		
		&_black:hover{
			border-color:$_color-black;
			span{
				color: $_color-black;
			}
		}
		
		&_print:hover , &_maps:hover{
			span{
				color: $_color-black;
			}
		}
		
		&_close:hover{
			span{
				color:lighten($_color-black,40%);
			}
		}

		&_gold:hover{
			&::before{
				background: $_color-main;
			}
			span{
				color: $_color-main;
			}
			
			span::before{
				border-color: $_color-main;
			}
		}
		
		&_secondry:hover{
			&::before{
				background: $_color-main;
			}
			span{
				color: $_color-main;
			}
			
			span::before{
				border-color: $_color-main;
			}
		}
	}
}
.c-tab{
	width: 100%;
	height: auto;
	display: block;
	margin: 0 auto;
	padding: .5rem 0;
	background: #9b9b9b;
	@include font-size(1.6rem);
	line-height: 2;
	text-align: center;
	font-weight: normal;
	font-style: normal;
	font-family: $_font-ja_serif;
	color: $_color-white;
	cursor: pointer;
	margin-top: 1rem;
	position: relative;
	&.active{
		background: $_color-main;
	}
	@include mq-min(lg){
		margin-top: 0;
	}
	@include mq-hover{
		@include transition(all , .5s);
		&:hover{
			background:  $_color-main;
		}
	}
	&::after {
		content: "";
		width: 45%;
		height: 100%;
		position: absolute;
		top: 0;
		background: linear-gradient(to right, rgba($_color-white, 0), rgba($_color-white, .4), rgba($_color-white, 0));
		transform: skewX(-20deg);
		left: -40%;
		animation: move01 3s infinite;
	}

	@keyframes move01 {
		0% {
			left: -100%;
		}

		60% {
			left: 130%;
		}

		100% {
			left: 130%;
		}
	}
}
	
.c-panel{
	&__tab{
		display: none;
		&.active{
			display: block;
		}
	}
}

.c-hover{
	@include mq-hover{
		@include transition(all , .5s);
		&:hover{
			opacity: .5;
		}
	}
}