/*	=============================================================================
	embed
	========================================================================== */

.u-embed {
	display: block;
	overflow: hidden;
	position: relative;
	width: 100%;
	height: auto;
	&_16to9{
		height: 0 !important;
		padding-bottom: percentage(9 / 16) !important;
	}
	&_4to3{
		height: 0 !important;
		padding-bottom: percentage(3 / 4) !important;
	}
	&__maps{
		position: relative;
		overflow: hidden;
		border: 1px solid #BEBEBE;
        &:before{
            content: "";
            display: block;
            padding-bottom: 60%;
            @include mq-min(lg){
                padding-bottom: 49.655172413793%;
            }
		}		
		#googlemap{
       		width: 100%;
        	height: 100%;
			background: $_color-text;	
			position: absolute;		
			top: 0;
			left: 0;
		}
	}
	&__item,iframe,embed,object,video,#googlemap {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
		border: 0;
	}
	
	
}