/*	=============================================================================
	media
	========================================================================== */

.c-image{
	$self : & ;
	position: relative;
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;
	
	&__item{
		display: block;
		position: relative;
		z-index: 1;
		width: 100%;
		height: auto;
		margin: 0;
	}
	
	&__caption{
		@include font-size(1.1rem);
		line-height: 1.8em;
		letter-spacing: .05em;
		@include justify();
		display: inline-block;
		padding: .3em .6em .4em;
		text-align: right;
		background: rgba($_color-black,.6);
		font-weight: normal;
		font-style: normal;
		color:$_color-white;
		width: auto;	
		position: absolute;	
		bottom: 0;
		right: 0;
		@include mq-min(lg){
			// margin-top: 2rem;
		}
		&_transparent{
			background: transparent;
			color: $_color-text;
			position: relative;
			bottom: auto;
			left: auto;
			display: block;
			text-align: right;
		}
		
		&_text{
			background: none;
			color: $_color-text;
		}
		
		&_white{
			background: transparent;
			color: $_color-white;
			z-index: 5;
		}
		
		&.-left{
			left: 0;
			right: auto;
		}
		&.-top{
			top: 0;
			bottom: auto;
		}
	}
}
.c-svg {
    position: relative;
    width: 100%;
	padding-top: 100%; /* SVG高さ / SVG幅 * 100 */
	max-width: 100%;
	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}