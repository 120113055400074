/*	=============================================================================
	display
	========================================================================== */

.u-dn{display: none !important;}
.u-di{display: inline !important;}
.u-dib{display: inline-block !important;}
.u-db{display: block !important;}
.u-df{display: flex !important;}

.u-print-none{
	@include mq-print(){
		display: none;
	}
}

@each $display in map-keys($breakpoints-min){
	@include mq-min($display){
		.u-dn-#{$display}{display: none !important;}
		.u-di-#{$display}{display: inline !important;}
		.u-dib-#{$display}{display: inline-block !important;}
		.u-db-#{$display}{display: block !important;}
		.u-df-#{$display}{display: flex !important;}
	}
}

.u-print-small{
	@include mq-print(){
		width: auto;
		height: 160mm !important;
		display: block;
		margin: 0 auto;
	}
}