/*	=============================================================================
	header
	========================================================================== */

.l-header{
	$cd: '../img/shared/';
	$self: &;
	
	width: 100%;
	padding: 1.5rem 1rem 1rem;
	display: block;
	margin: 0 auto;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9998;
	@include mq-min(lg){
		padding: 1rem;
	}
	
	&.isActive{
		pointer-events: none;
	}
	
	@include mq-min(lg){
		padding: 2.5rem;
	}
	@include mq-print(){
		display: none;
	}
	&__logo{
		width: 200px;
		display: block;
		position: relative;
		z-index: 9999;
		
		a , img{
			width: 100%;
			height: auto;
			display: block;
			position: relative;
			z-index: 2;
		}
		a{
			display: block;
			@include mq-hover{
				@include transition(all , .5s);
				&:hover{
					opacity: .5;
				}
			}
		}
		@include mq-min(lg){
			width: 160px;
		}
		
		.isActive &{
			pointer-events: all;
			cursor: pointer;
		}
	}
	&__menu{
		position: absolute;
		top: 1.8rem;
		right: 1.5rem;
		width: 3.5rem;
		height: 1.8rem;
		z-index: 9998;
		@include mq-min(lg){
			top: 2.5rem;
			right: 2.5rem;
		}
		.isActive &{
			pointer-events: all;
			cursor: pointer;
		}
		& .c-burger::after{
			content: '';
			width: 8px;
			height: 8px;
			border-radius: 4px;
			position: absolute;
			top: -4px;
			right: -4px;
			background: #830f0f;
			display: block;
			@include transition(all , .5s);
		}
		& .c-burger.isOpen::after{
			opacity: 0;
		}
	}
}