/*	=============================================================================
	footer
	========================================================================== */

.l-footer{
	$cd: '../img/shared/';
	$self: &;
	width: 100%;
	height: auto;
	display: block;
	padding: 6rem 0;
	background:#343748;
	position: relative;
	&::after{
		content: "";
		width: 60%;
		height: 100%;
		position: absolute;
		bottom: 0;
		right: 0;
		background: url(#{$cd}footer-bg.svg) bottom right no-repeat;
		background-size: contain;
		opacity: .2;
		pointer-events: none;
		z-index: 1;
		@include mq-min(lg){
			width: 30%;
		}
	}
	&__logo{
		width: 100px;
		display: block;
		position: relative;
		z-index: 100;
		margin: 0 auto;
		a , img{
			width: 100%;
			height: auto;
			display: block;
			position: relative;
			z-index: 2;
		}
		a{
			display: block;
			@include mq-hover{
				@include transition(all , .5s);
				&:hover{
					opacity: .5;
				}
			}
		}
		@include mq-min(lg){
			width: 200px;
		}
		
	}
	
	&__copy{
		width: 100%;
		height: auto;
		display: block;
		padding: 1rem 0 3rem;
		background: $_color-black;
		color: $_color-white;
		@include font-size(1rem);
		line-height: 2;
		letter-spacing: .15em;
		font-family: $_font-ja_sans-serif;
		text-align:center;
		text-indent: .15em;
		@include mq-min(lg){
			padding: .5rem 0;
			@include font-size(1.3rem);
		}
	}
}